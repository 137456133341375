import { mixins } from "@/plugins/mixins";
export default {
  name: "monitor-frame",
  mixins: [mixins],
  components: {},
  data() {
    return {
      logDialog: false,
      logData: [],
      // 日志data
      dataTotal: 0,
      currentPage: 1,
      pageSize: 10,
      pageSizeOptions: [10, 20, 30, 40, 50, 100],
      columns: [{
        //     label: '仓库',
        //     prop: 'warehouseName',
        // }, {
        label: '车架编号',
        prop: 'frameCode'
      }, {
        label: '所在位置',
        prop: 'positionType'
      }, {
        label: '具体位置',
        prop: 'position'
      }, {
        label: '厂家',
        prop: 'manufacturer'
      }, {
        //     label: '车辆编号',
        //     prop: 'deviceCode',
        // }, {
        label: '车牌',
        prop: 'plateNo'
      }, {
        label: '上牌时间',
        prop: 'plateTime'
      }, {
        label: '保险编号',
        prop: 'insureCode'
      }, {
        label: '保险名称',
        prop: 'insureName'
      }, {
        label: '保险公司',
        prop: 'insureCompany'
      }, {
        label: '保险开始日期',
        prop: 'insureBegin'
      }, {
        label: '保险到期日期',
        prop: 'insureEnd'
      }, {
        label: '设备状态',
        prop: 'deviceStatusDesc'
      }, {
        label: '启用状态',
        prop: 'statusDesc'
      }],
      topButtons: [],
      linkButtons: [{
        name: '日志',
        code: 'monitorFrameLog',
        click: this.getLog,
        permissions: ['monitorFrameLog']
      }],
      searchItems: [{
        //     prop: 'warehouseId',
        //     label: '仓库',
        //     type: 'select',
        //     items: [],
        //     itemProp: {
        //         label: 'name',
        //         value: 'id'
        //     },
        //     remote: true,
        //     loading: false,
        //     remoteMethod: (name) => {
        //         if (name.length >= 1) {
        //             this.searchItems[0].loading = true;
        //             this.$api.business.carWarehouse.getList({
        //                 page: 1,
        //                 size: 20,
        //                 name: name
        //             }).then(res => {
        //                 if (res.code === 200) {
        //                     this.searchItems[0].items = res.data.records;
        //                 }
        //             }).finally(() => {
        //                 this.searchItems[0].loading = false;
        //             })
        //         }
        //     }
        // }, {
        label: '车架编号',
        prop: 'frameCode'
      }, {
        label: '车牌',
        prop: 'plateNo'
      }, {
        label: '保险编号',
        prop: 'insureCode'
      }, {
        label: '保险公司',
        prop: 'insureCompany'
      }, {
        prop: 'time',
        propItems: ['plateStartTime', 'plateEndTime'],
        label: '上牌时间范围',
        type: 'date',
        dateType: 'daterange',
        valueFormat: 'YYYY-MM-DD'
      }, {
        prop: 'positionType',
        label: '所在位置',
        type: 'select',
        itemProp: {
          label: 'label',
          value: 'value'
        },
        items: [{
          label: '充电站',
          value: 'chargingStation'
        }, {
          label: '车辆',
          value: 'vehicle'
        }, {
          label: '仓库',
          value: 'warehouse'
        }, {
          label: '运维人员',
          value: 'user'
        }]
      }, {
        prop: 'deviceStatus',
        label: '设备状态',
        type: 'select',
        items: ['正常', '检修', '报废']
      }]
    };
  },
  methods: {
    getLog(row) {
      this.$api.business.deviceOperationLog.getList({
        page: this.currentPage,
        size: this.pageSize,
        deviceId: row.id
      }).then(res => {
        this.logData = res.data.records;
        this.dataTotal = res.data.total;
        this.logDialog = true;
      });
    }
  }
};
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_layout_breadcrumb = _resolveComponent("layout-breadcrumb");
  const _component_table_page = _resolveComponent("table-page");
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_el_table = _resolveComponent("el-table");
  const _component_el_pagination = _resolveComponent("el-pagination");
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_layout_breadcrumb, {
    topMenuId: _ctx.topMenuId,
    onChangeTopToMenu: _ctx.changeTopToMenu,
    topMenuClick: _ctx.topMenuClick
  }, null, 8, ["topMenuId", "onChangeTopToMenu", "topMenuClick"]), _createVNode(_component_table_page, {
    fetchApi: _ctx.$api.business.deviceMonitor.getDeviceFrameMonitorList,
    columns: $data.columns,
    topButtons: $data.topButtons,
    linkButtons: $data.linkButtons,
    searchItems: $data.searchItems,
    selectionEnable: false,
    ref: "tablePage"
  }, null, 8, ["fetchApi", "columns", "topButtons", "linkButtons", "searchItems"]), _createVNode(_component_el_dialog, {
    modelValue: $data.logDialog,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.logDialog = $event),
    title: "操作日志",
    "destroy-on-close": "",
    "close-on-click-modal": false
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table, {
      data: $data.logData,
      style: {
        "width": "100%"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_table_column, {
        prop: "deviceCode",
        label: "编号"
      }), _createVNode(_component_el_table_column, {
        prop: "logType",
        label: "操作类型"
      }), _createVNode(_component_el_table_column, {
        prop: "createUserName",
        label: "操作人员"
      }), _createVNode(_component_el_table_column, {
        prop: "createTime",
        label: "操作时间"
      })]),
      _: 1
    }, 8, ["data"]), _ctx.showPagination ? (_openBlock(), _createBlock(_component_el_pagination, {
      key: 0,
      layout: "total, prev, pager, next, sizes",
      total: $data.dataTotal,
      "page-size": $data.pageSize,
      "onUpdate:pageSize": _cache[0] || (_cache[0] = $event => $data.pageSize = $event),
      "current-page": $data.currentPage,
      "onUpdate:currentPage": _cache[1] || (_cache[1] = $event => $data.currentPage = $event),
      "page-sizes": $data.pageSizeOptions,
      class: "table-pagination"
    }, null, 8, ["total", "page-size", "current-page", "page-sizes"])) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["modelValue"])]);
}